import React from 'react'
import css from '@styled-system/css'
import { connectHits } from 'react-instantsearch-dom'
import { Box, Flex, Card } from 'components/primitives'
import { Row, Column } from 'components/core/Layout'
import Heading from 'components/core/Heading'
import Text from 'components/core/Text'
import Link from 'components/core/Link'
import Image from 'components/core/Image'
import Badge from 'components/core/Badge'
import differenceInDays from 'date-fns/differenceInDays'
import fromUnixTime from 'date-fns/fromUnixTime'
import Flag from 'components/core/Flag'
import Relative from 'components/core/Relative'
import Absolute from 'components/core/Absolute'

const HitItem = ({ hit, ...props }) => {
  const updated =
    differenceInDays(new Date(), fromUnixTime(hit.updated_at)) < 6
      ? true
      : false

  return (
    <Box as='li' mb={2} {...props}>
      <Link
        display='flex'
        alignItems='center'
        variant='none'
        color='inherit'
        p={2}
        to={`/products/${hit.handle}`}
        css={css({ borderRadius: 2, ':hover': { bg: 'lightGray' } })}
      >
        <Box mr='auto'>
          <Flex alignItems='center' mb={2}>
            <Heading fontSize={2}>{hit.name}</Heading>
            {updated && !hit.obsolete && (
              <Card
                ml={2}
                as='span'
                border='1px solid'
                borderColor='red'
                color='red'
                fontSize={10}
                borderRadius={1}
                px='2px'
              >
                NEW
              </Card>
            )}
            {hit.obsolete && (
              <Card
                ml={2}
                as='span'
                border='1px solid'
                borderColor='lightText'
                color='lightText'
                fontSize={10}
                borderRadius={1}
                px='2px'
              >
                SOLD OUT
              </Card>
            )}
          </Flex>
          <Text fontSize={1} mb={1}>
            <Text color='lightText' as='span' mr={2}>
              사이즈
            </Text>
            {hit.derived_size}
          </Text>
          <Text fontSize={1}>
            <Text color='lightText' as='span' mr={2}>
              가격
            </Text>
            ￦{hit.price_per_coverage_without_tax.toLocaleString()} / m²
          </Text>
          <Box mt={2}>
            {hit.staff_pick && !hit.obsolete && (
              <Badge fontSize={10} bg='blue' mr={1}>
                <span role='img' aria-label='반짝 반짝'>
                  ✨
                </span>
                스태프 추천
              </Badge>
            )}
          </Box>
        </Box>
        <Image
          src={hit.thumbnail}
          borderRadius={1}
          border='1px solid'
          borderColor='muted'
          width={64}
          height={64}
        />
      </Link>
    </Box>
  )
}

const HitCard = ({ hit, ...props }) => {
  const updated =
    differenceInDays(new Date(), fromUnixTime(hit.updated_at)) < 6
      ? true
      : false

  return (
    <Box as='li' mb={2} {...props}>
      <Link
        display='block'
        variant='none'
        color='inherit'
        p={2}
        to={`/products/${hit.handle}`}
        css={css({ borderRadius: 2, ':hover': { bg: 'lightGray' } })}
      >
        <Relative>
          {hit.staff_pick && !hit.obsolete && (
            <Absolute top={8} left={0}>
              <Flag bg='blue' fontWeight='bold'>
                스태프 추천{' '}
                <span role='img' aria-label='반짝 반짝'>
                  ✨
                </span>
              </Flag>
            </Absolute>
          )}
          <Image
            src={hit.thumbnail}
            borderRadius={2}
            border='1px solid'
            borderColor='lightGray'
            mb={3}
          />
        </Relative>
        <Heading fontSize={[1, 3]} fontWeight='heavy'>
          {hit.name}
        </Heading>
        <Text fontSize={[0, 2, 3]}>{hit.derived_size}</Text>
        <Flex mt={2} alignItems='center'>
          {updated && !hit.obsolete && (
            <Card
              border='1px solid'
              borderColor='red'
              borderRadius={1}
              color='red'
              fontSize={[10, 0]}
              px={['2px', 1]}
              ml='auto'
            >
              NEW
            </Card>
          )}
          {hit.obsolete && (
            <Card
              border='1px solid'
              borderColor='lightText'
              borderRadius={1}
              color='lightText'
              fontSize={[10, 0]}
              px={['2px', 1]}
              ml='auto'
            >
              SOLD OUT
            </Card>
          )}
        </Flex>
      </Link>
    </Box>
  )
}

const Hits = ({ hits, ...props }) => (
  <Box as='ul' {...props}>
    {hits.map(hit => (
      <HitItem key={hit.objectID} hit={hit} />
    ))}
  </Box>
)

const HitCardList = ({ hits, ...props }) => (
  <Row mx={[0, -1, -3]} as='ul' {...props}>
    {hits.map(hit => (
      <Column
        px={[0, 1, 3]}
        key={hit.objectID}
        width={[1 / 2, 1 / 2, null, 1 / 3, 1 / 4]}
        mb={[3, 4]}
      >
        <HitCard hit={hit} />
      </Column>
    ))}
  </Row>
)
export const HitCards = connectHits(HitCardList)
export default connectHits(Hits)
