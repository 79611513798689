import React from 'react'
import css from '@styled-system/css'
import Select from 'components/core/Select'
import Label from 'components/core/Label'

const StyledLabel = props => (
  <Label color='background' fontWeight='body' css={css({ mb: 1 })} {...props} />
)

const StyledSelect = props => (
  <Select
    color='background'
    borderColor='transparent'
    focusColor='secondary'
    fontWeight='bold'
    css={css({ px: '4px', py: '2.5px', lineHeight: 'body' })}
    {...props}
  />
)

StyledLabel.isLabel = true
StyledSelect.isField = true

const SearchFilter = ({ id, label, ...props }) => (
  <React.Fragment>
    <StyledLabel pl={1} htmlFor={id}>
      {label}
    </StyledLabel>
    <StyledSelect id={id} {...props} />
  </React.Fragment>
)

export default SearchFilter
