import React from 'react'
import css from '@styled-system/css'
import { connectSearchBox } from 'react-instantsearch-dom'
import { Box } from 'components/primitives'
import SearchIcon from 'components/icons/feather-search.svg'
import IconField from 'components/core/IconField'
import Label from 'components/core/Label'
import Input from 'components/core/Input'

const id = 'search'

const StyledInput = props => (
  <Input
    color='background'
    borderColor='text'
    focusColor='text'
    bg='secondary'
    css={css({
      '::placeholder': {
        color: 'background'
      },
      ':focus': {
        boxShadow: `0 0 0 1px #181818`
      }
    })}
    {...props}
  />
)

StyledInput.isField = true

const SearchInput = ({ value, onChange, ...props }) => {
  return (
    <IconField {...props}>
      <SearchIcon color='white' size={16} zIndex={1} />
      <Label htmlFor={id} hidden>
        타일 검색
      </Label>
      <StyledInput
        id={id}
        name='search'
        type='search'
        placeholder='타일 검색'
        autoComplete='off'
        value={value}
        onChange={onChange}
      />
    </IconField>
  )
}

const SearchBox = ({
  currentRefinement,
  isSearchStalled,
  refine,
  delay,
  ...props
}) => {
  const [value, setValue] = React.useState(currentRefinement)
  let timerId = null

  const onChangeDebounced = event => {
    const input = event.currentTarget.value
    clearTimeout(timerId)
    timerId = setTimeout(() => refine(input), delay)
    setValue(input)
  }

  return (
    <Box
      as='form'
      role='search'
      action=''
      onSubmit={e => e.preventDefault()}
      noValidate
      {...props}
    >
      <SearchInput value={value} onChange={onChangeDebounced} />
    </Box>
  )
}

export default connectSearchBox(SearchBox)
