import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Card } from 'components/primitives'

// Extends Card.
// * Sets size based on height. (32, 40, 48) [1]
// * Visually sets disabled state. [2]
// * Prevents line height mode from changing button size. [3]

// [1]

const variant = ({ variant, theme: { fontSizes } }) => {
  switch (variant) {
    case 'small':
      return {
        fontSize: fontSizes[0],
        padding: '8.5px 12px'
      }
    case 'medium':
      return {
        fontSize: fontSizes[1],
        padding: '11px 18px'
      }
    case 'large':
      return {
        fontSize: fontSizes[2],
        padding: '13.5px 22px'
      }
    default:
      return {
        fontSize: fontSizes[1],
        padding: '11px 18px'
      }
  }
}

// [2]

const state = ({ disabled, theme: { colors } }) => ({
  ':disabled': {
    opacity: 0.25,
    cursor: 'not-allowed'
  },
  ':hover': {
    backgroundColor: disabled ? null : colors.secondary
  }
})

// [3]

const type = ({ bg, color, disabled, theme: { colors } }) => {
  const buttonColors = {
    text: {
      backgroundColor: colors.text,
      color: colors.white,
      ':hover': {
        backgroundColor: disabled ? null : colors.darkGray
      }
    },
    transparent: {
      backgroundColor: 'transparent',
      color: colors.text,
      ':hover': {
        backgroundColor: disabled ? null : '#ad8840'
      }
    },
    primary: {
      backgroundColor: colors.primary,
      color: colors.white,
      ':hover': {
        backgroundColor: disabled ? null : colors.secondary
      }
    }
  }
  return !(bg && color) && (buttonColors[bg] || buttonColors.text)
}

const Button = styled(Card)(
  {
    appearance: 'none',
    textDecoration: 'none',
    cursor: 'pointer',
    userSelect: 'none',
    WebkitFontSmoothing: 'antialiased'
  },
  state,
  variant,
  type
)

Button.propTypes = {
  variant: PropTypes.oneOf(['small', 'medium', 'large'])
}

Button.defaultProps = {
  as: 'button',
  type: 'button',
  display: 'inline-block',
  textAlign: 'center',
  lineHeight: 'base', // [3]
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'bold',
  m: 0,
  px: 3,
  py: 2,
  bg: 'primary',
  border: 0,
  borderRadius: 1
}

Button.displayName = 'Button'

export default Button
