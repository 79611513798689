import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex } from 'components/primitives'

// Layout primitives based on Box and Flex
// * Calculates 12-column based grid. [1]
// * Makes columns auto-layout when width prop is not provided. [2]
// * Counteracts on the composed column's gutter via negative margins. If there
//   are no child column components, set mx to 0 or use <Flex /> instead. [3]

export const Layout = props => (
  <Box display='flex' flexDirection='column' minHeight='100vh' {...props} />
)

export const header = {
  as: 'header',
  display: 'flex'
}

export const footer = {
  as: 'footer',
  display: 'flex'
}

export const main = {
  as: 'main',
  flex: '1 1 auto'
}

export const container = {
  minWidth: 0,
  maxWidth: 1280,
  width: '100%',
  mx: 'auto',
  px: [3, 4]
}

export const fullContainer = {
  minWidth: 0,
  width: '100%',
  mx: 'auto',
  px: [3, 4]
}

export const postContainer = {
  minWidth: 0,
  maxWidth: 768,
  width: '100%',
  mx: 'auto',
  px: [3, 4]
}

export const col = num => `${(num / 12) * 100}%` // [1]

const width = ({ width }) => !width && { flex: '1 1 auto' } // [2]

export const Column = styled(Box)(width)

Column.defaultProps = {
  px: 3
}

export const Row = props => <Flex mx={-3} flexWrap='wrap' {...props} /> // [3]
