import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { Flex, Box, Card } from 'components/primitives'
import SearchControl from 'components/modules/SearchControl'
import Pagination from 'components/modules/Pagination'
import Heading from 'components/core/Heading'
import Text from 'components/core/Text'
import 'instantsearch.css/themes/reset.css'
import 'components/modules/algolia.css'
import { CurrentRefinements } from 'react-instantsearch-dom'
import find from 'lodash/find'
import toString from 'lodash/toString'
import isEqual from 'lodash/isEqual'
import { connectStateResults } from 'react-instantsearch-dom'
import qs from 'qs'
import { navigate } from 'gatsby'

const BaseStateResults = ({ searchResults, searchState, ...props }) => {
  const nbHits = searchResults && searchResults.nbHits
  const hasResults = searchResults && searchResults.nbHits !== 0
  const hasQuery = !!searchState.query
  const hasFilterType = searchState.menu && !!searchState.menu.tile_type
  const hasFilterColors = searchState.menu && !!searchState.menu.colors
  const hasFilterSize = searchState.menu && !!searchState.menu.filterable_size
  const hasFilterSurface = searchState.menu && !!searchState.menu.surface
  const hasAnyFilter =
    hasFilterType || hasFilterColors || hasFilterSize || hasFilterSurface

  const getLabel = () => {
    if (hasQuery && !hasAnyFilter) {
      return '검색어'
    }
    if (!hasQuery && hasAnyFilter) {
      return '필터'
    }
    if (hasQuery && hasAnyFilter) {
      return '검색어와 필터'
    }
    return null
  }

  const label = getLabel()

  if (label) {
    return (
      <Card
        mx='auto'
        maxWidth={450}
        bg='lightYellow'
        p={3}
        borderRadius={1}
        mb={4}
      >
        <Heading mb={1} fontWeight='heavy'>
          {label}가 적용된 결과
        </Heading>
        <Text mb={3}>
          {hasResults
            ? `현재 입력하신 ${label}에 해당하는 ${nbHits}개 제품 목록을 보고 있습니다. 만약 찾는
          제품이 보이지 않는다면 입력하신 ${label}를 조정해보세요!`
            : `입력하신 ${label}에 해당하는 제품이 없습니다.`}
        </Text>
        {hasQuery && <Text>검색어: {searchState.query}</Text>}
        <CurrentRefinements
          transformItems={items =>
            items.map(item => {
              const label = `${mapAttributeToLabel(item.attribute)}: ${
                item.currentRefinement
              }`
              return {
                ...item,
                label
              }
            })
          }
        />
      </Card>
    )
  }

  return null
}
const StateResults = connectStateResults(BaseStateResults)

const searchClient = algoliasearch(
  'ZK3RA3ABTJ',
  'e7b73395a9ab05aeb44908f19a70a8fc'
)

const refinements = [
  {
    attribute: 'tile_type',
    label: '타일 종류'
  },
  {
    attribute: 'colors',
    label: '색상'
  },
  {
    attribute: 'filterable_size',
    label: '사이즈'
  },
  {
    attribute: 'surface',
    label: '표면'
  }
]

const mapAttributeToLabel = attribute => {
  const refinement = find(refinements, ['attribute', attribute])
  return refinement.label
}

const getCollection = data =>
  data && data.handle !== 'all' ? { filters: `tags:${data.name}` } : null

// const getTypeSlug = name =>
//   name
//     .split(' ')
//     .map(encodeURIComponent)
//     .join('-')

// const getTypeName = slug =>
//   slug
//     .split('-')
//     .map(decodeURIComponent)
//     .join(' ')

// const createURL = state => {
//   const isDefaultRoute =
//     !state.query && state.page === 1 && (state.menu && !state.menu.tile_type)
//   if (isDefaultRoute) {
//     return ''
//   }

//   const typePath = state.menu.tile_type
//     ? `${getTypeSlug(state.menu.tile_type)}/`
//     : ''

//   const queryParameters = {}

//   if (state.query) {
//     queryParameters.query = encodeURIComponent(state.query)
//   }

//   if (state.page !== 1) {
//     queryParameters.page = state.page
//   }

//   const queryString = qs.stringify(queryParameters, {
//     addQueryPrefix: true,
//     arrayFormat: 'repeat'
//   })

//   return `/products/${typePath}${queryString}`
// }

const createURL = state => {
  const s = { menu: state.menu, page: state.page, query: state.query }
  return `?${qs.stringify(s)}`
}

// const searchStateToUrl = searchState =>
//   searchState ? createURL(searchState) : ''

const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : ''

// const urlToSearchState = location => {
//   const pathnameMatches = location.pathname.match(/products\/(.*?)\/?$/)
//   const type = getTypeName((pathnameMatches && pathnameMatches[1]) || '')
//   const { query = '', page = 1 } = qs.parse(location.search.slice(1))

//   return {
//     page,
//     query: decodeURIComponent(query),
//     menu: {
//       tile_type: decodeURIComponent(type)
//     }
//   }
// }

const urlToSearchState = ({ search }) => qs.parse(search.slice(1))

const DEBOUNCE_TIME = 400

const Search = ({ children, hitsPerPage, location, pageData, ...props }) => {
  const [searchState, setSearchState] = React.useState(
    typeof window !== `undefined` ? urlToSearchState(location) : null
  )

  React.useEffect(() => {
    const newSearchState = urlToSearchState(location)
    if (!isEqual(searchState, newSearchState)) {
      setSearchState(newSearchState)
    }
  }, [location, searchState])

  const [debouncedSetState, setDebouncedSetState] = React.useState(null)

  const onSearchStateChange = updatedSearchState => {
    const page = toString(updatedSearchState.page)
    const hitsPerPage = toString(updatedSearchState.configure.hitsPerPage)
    const searchState = {
      ...updatedSearchState,
      page,
      configure: { hitsPerPage }
    }

    clearTimeout(debouncedSetState)

    setDebouncedSetState(
      setTimeout(() => {
        navigate(searchStateToUrl(location, searchState), {
          state: searchState
        })
      }, DEBOUNCE_TIME)
    )

    setSearchState(searchState)
  }

  return (
    <Box mb={4} {...props}>
      <InstantSearch
        searchClient={searchClient}
        indexName='tmtt'
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure hitsPerPage={hitsPerPage} {...getCollection(pageData)} />
        <Flex alignItems='center' flexDirection='column'>
          <SearchControl minWidth={288} mb={4} />
        </Flex>
        {children}
        <StateResults />
        <Flex>
          <Pagination ml='auto' />
        </Flex>
      </InstantSearch>
    </Box>
  )
}

export default Search
