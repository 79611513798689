import React from 'react'
import css from '@styled-system/css'
import sortBy from 'lodash/sortBy'
import { Box, Card } from 'components/primitives'
import Heading from 'components/core/Heading'
import Text from 'components/core/Text'
import Link from 'components/core/Link'
import { Column, Row } from 'components/core/Layout'

export const ProductCard = ({ variant = 'small', product, ...props }) => {
  const productImage = sortBy(product.images, ['filename'])[0]

  return (
    <Box>
      <Link
        display='block'
        variant='none'
        color='inherit'
        p={1}
        to={`/products/${product.handle}`}
        css={css({ borderRadius: 3, ':hover': { bg: 'lightGray' } })}
        {...props}
      >
        <Card
          px={64}
          py={92}
          backgroundImage={`url(${productImage.thumbnails.large.url})`}
          backgroundSize='cover'
          borderRadius={2}
          border='1px solid'
          borderColor='lightGray'
          mb={2}
        />
        <Heading fontSize={3} fontWeight='heavy'>
          {product.name}
        </Heading>
        <Text>{product.derived_size}</Text>
        <Text>
          ￦{product.price_per_coverage_without_tax.toLocaleString()} / m²
        </Text>
      </Link>
    </Box>
  )
}

// const ProductList = ({ products, ...props }) => (
//   <HorizontalScrollContainer {...props}>
//     {products.map(product => (
//       <ProductCard key={product.handle} product={product} />
//     ))}
//   </HorizontalScrollContainer>
// )

export default ({ products, ...props }) => {
  return (
    <Row mx={-1} {...props}>
      {products.map(product => (
        <Column key={product.handle} px={1} width={[1 / 2, null, 1 / 4]}>
          <ProductCard key={product.handle} product={product} />
        </Column>
      ))}
    </Row>
  )
}
