import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Text from './Text'

// Extends Text.
// * htmlFor: matches <input> id. It's auto-handled in FormField.
// * autoHide: shows hidden label as user types. It's used in FormField.
// * inside: puts label inside input border. It's used in FormField.
// * nowrap: inlines label for inputs such as radio buttons.
// * hidden: visually hides label. If you use this, provide other indicator.

const nowrap = ({ nowrap }) => (nowrap ? { whiteSpace: 'nowrap' } : null)

const accessiblyHide = ({ hidden }) =>
  hidden
    ? {
        position: 'absolute',
        width: '1px',
        height: '1px',
        clip: 'rect(1px, 1px, 1px, 1px)'
      }
    : null

const Label = styled(Text)(nowrap, accessiblyHide)

Label.propTypes = {
  autoHide: PropTypes.bool,
  inside: PropTypes.bool,
  nowrap: PropTypes.bool,
  hidden: PropTypes.bool
}

Label.defaultProps = {
  ...Text.defaultProps,
  as: 'label',
  display: 'block',
  letterSpacing: 0.2,
  m: 0,
  width: '100%',
  fontSize: 0,
  fontWeight: 'bold',
  color: 'lightText'
}

Label.isLabel = true

export default Label
