import React from 'react'
import { Card } from 'components/primitives'

// Extends Card.

const Image = props => (
  <Card as='img' maxWidth='100%' height='auto' m={0} {...props} />
)

export default Image
