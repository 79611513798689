import styled from '@emotion/styled'
import { Link as RouterLink } from 'gatsby'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import Text from './Text'

// Extends Text.
// * Decided not to combine external/internal links because we may have links
//   that look like buttons or vice versa. It's simpler to do use as and to/href
//   props instead of making Link API complex.
//   https://github.com/styled-system/styled-system/blob/master/docs/src/link.js
//   https://www.gatsbyjs.org/docs/gatsby-link
// * Removes default button styling so you can do as='button'. [1]
// * Sets underline or color-based interaction. [2]
// * Defaults to RouterLink as it's used more often than 'a'. [3]

// [1]

const button = ({ as }) =>
  as === 'button' &&
  css({
    border: 0,
    p: 0,
    bg: 'transparent'
  })

// [2]

const variant = ({ variant, theme: { colors } }) => {
  switch (variant) {
    case 'underline':
      return {
        textDecoration: 'underline',
        ':hover': {
          color: colors.secondary
        }
      }
    case 'color':
      return {
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline'
        }
      }
    case 'none':
      return {
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'none'
        }
      }
    default:
      return {
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline'
        }
      }
  }
}

const Link = styled(Text)(
  {
    cursor: 'pointer'
  },
  variant,
  button
)

Link.propTypes = {
  variant: PropTypes.oneOf(['underline', 'color', 'none'])
}

Link.defaultProps = {
  ...Text.defaultProps,
  as: RouterLink,
  color: 'primary',
  fontSize: 'inherit'
}

export default Link
