import styled from '@emotion/styled'
import { Card } from 'components/primitives'

// Takes 'bg' prop to determine color and renders a primitive badge.

const type = props => {
  const badgeColors = {
    text: {
      backgroundColor: props.theme.colors.text,
      color: props.theme.colors.white
    },
    gray: {
      backgroundColor: props.theme.colors.gray,
      color: props.theme.colors.white
    },
    lightGray: {
      backgroundColor: props.theme.colors.lightGray,
      color: props.theme.colors.text
    },
    blue: {
      backgroundColor: props.theme.colors.blue,
      color: props.theme.colors.white
    }
  }
  return (
    !(props.bg && props.color) && (badgeColors[props.bg] || badgeColors.text)
  )
}

const Badge = styled(Card)(
  {
    WebkitFontSmoothing: 'antialiased'
  },
  type
)

Badge.defaultProps = {
  display: 'inline-block',
  borderRadius: 1,
  fontSize: 0,
  fontWeight: 700,
  px: 2,
  py: 1
}

export default Badge
