import styled from '@emotion/styled'
import { space, color, position } from 'styled-system'

// Renders svg icon as a component. aria-hidden="true" and focusable="false" are
// set by default to icons. When a title prop is passed, aria-hidden is set to
// false. In order to support older browsers, please pass in a titleId. To add a
// descripton, please pass in desc and descId .
// * color, space
// * title, titleId
// * desc, descId

const Svg = styled('svg')(
  {
    flex: 'none'
  },
  space,
  color,
  position
)

export default Svg
