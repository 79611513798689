import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Card, Flex, Box } from 'components/primitives'
import Link from 'components/core/Link'
import Text from 'components/core/Text'
import Heading from 'components/core/Heading'
import Button from 'components/core/Button'
import Divider from 'components/core/Divider'
import MessageIcon from 'components/icons/pcln-chat.svg'
import PhoneIcon from 'components/icons/pcln-phone.svg'

const Banner = props => {
  const {
    site: {
      siteMetadata: { contact }
    }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            email
            phone
            fax
          }
        }
      }
    }
  `)

  return (
    <Card
      mx='auto'
      maxWidth={450}
      bg='accent'
      p={3}
      borderRadius={1}
      mb={24}
      {...props}
    >
      <Flex mb={1} flexDirection='column' alignItems='center'>
        <Heading mb={2}>궁금한 게 있나요?</Heading>
        <Box>
          <Flex justifyContent='center'>
            <Button as='a' bg='transparent' href={`tel:${contact.phone}`}>
              <PhoneIcon />
              <Text fontSize={[1, 2]}>전화하기</Text>
            </Button>
            {/* <Button
              bg='transparent'
              onClick={() => {
                window.Intercom('show')
              }}
            >
              <MessageIcon />
              <Text fontSize={[1, 2]}>채팅하기</Text>
            </Button> */}
          </Flex>
        </Box>
      </Flex>
      <Divider mt={2} mb={2} borderColor='text' />
      <Link
        as='a'
        display='block'
        mb={1}
        color='text'
        textAlign='center'
        href={`tel:${contact.phone}`}
        fontSize={[0, 1]}
      >
        {contact.phone}
      </Link>
      <Link
        as='a'
        textAlign='center'
        display='block'
        color='text'
        href={`mailto:${contact.email}`}
        fontSize={[0, 1]}
      >
        {contact.email}
      </Link>
    </Card>
  )
}

export default Banner
