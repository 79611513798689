import React from 'react'
import { connectMenu } from 'react-instantsearch-dom'
import SearchFilter from 'components/modules/SearchFilter'

const MenuSelect = ({ id, name, label, items, currentRefinement, refine }) => (
  <SearchFilter
    id={id}
    name={name}
    label={label}
    value={currentRefinement || ''}
    onChange={event => refine(event.currentTarget.value)}
  >
    <option value=''>‒</option>
    {items.map(item => (
      <option
        key={item.label}
        value={item.isRefined ? currentRefinement : item.value}
      >
        {item.label}
      </option>
    ))}
  </SearchFilter>
)

export default connectMenu(MenuSelect)
