import styled from '@emotion/styled'
import { Box } from 'components/primitives'
import { top, right, bottom, left, zIndex } from 'styled-system'

const Relative = styled(Box)`
  position: relative;
  ${top} ${bottom} ${left} ${right}
  ${zIndex}
`

Relative.propTypes = {
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...zIndex.propTypes
}

Relative.displayName = 'Relative'

export default Relative
