import React from 'react'
import { Flex } from 'components/primitives'

// Groups input with icons. Icons can be placed left, right or both. You might
// want to pass autoComplete='off' if native autofill is causing troubles.
// Typically you don't need to use this component as it's used internally.
// * Sets margin and padding based on icon size, assuming default icon size = 24.

const IconField = props => {
  const children = React.Children.toArray(props.children).filter(
    child => child.type.isField || child.type.isIcon
  )
  const [icon] = children.filter(child => child.type.isIcon)
  const iconMargin = icon && 24 - icon.props.size - 32
  const iconPadding = icon && 40 - (24 - icon.props.size)

  const styledChildren = children.map((child, i) => {
    if (child.type.isIcon) {
      return React.cloneElement(child, {
        style: {
          ...child.props.style,
          flex: 'none',
          alignSelf: 'center',
          pointerEvents: 'none',
          marginLeft: i === 0 ? 8 : iconMargin,
          marginRight: i === 0 ? iconMargin : 8
        }
      })
    }

    return React.cloneElement(child, {
      style: {
        ...child.props.style,
        paddingLeft: i === 0 ? undefined : iconPadding,
        paddingRight: i === children.length - 1 ? undefined : iconPadding
      }
    })
  })

  return <Flex {...props}>{styledChildren}</Flex>
}

export default IconField
