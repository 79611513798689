import styled from '@emotion/styled'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import { Card } from 'components/primitives'

// Extends Card.
// * id: matches <label> htmlFor. It's auto handled in FormField.
// * disabled: visually sets disabled state.
// * variant: sets size based on height. (32, 36, 48)
// * borderColor: sets border color.
// * focusColor: sets border color when focused.

const variant = ({ variant }) => {
  switch (variant) {
    case 'small':
      return {
        padding: '6px 12px'
      }
    case 'medium':
      return {
        padding: '8px 12px'
      }
    case 'large':
      return {
        padding: '14px 12px'
      }
    default:
      return {
        padding: '8px 12px'
      }
  }
}

const borderColor = ({ borderColor, focusColor, theme }) => {
  const c = borderColor ? theme.colors[borderColor] : theme.colors.gray
  const f = focusColor ? theme.colors[focusColor] : theme.colors.primary

  return {
    borderColor: c,
    boxShadow: `0 0 0 1px ${c}`,
    ':focus': {
      outline: 0,
      borderColor: f,
      boxShadow: `0 0 0 1px ${f}`
    }
  }
}

const Input = styled(Card)(
  css({
    appearance: 'none',
    ':disabled': {
      opacity: 0.25
    },
    '::placeholder': {
      color: 'lightText'
    },
    '::-ms-clear': {
      display: 'none'
    }
  }),
  borderColor,
  variant
)

Input.propTypes = {
  variant: PropTypes.oneOf(['medium', 'large'])
}

Input.defaultProps = {
  as: 'input',
  display: 'block',
  fontSize: 2,
  m: 0,
  width: '100%',
  borderWidth: 0,
  borderRadius: 1,
  borderStyle: 'solid',
  bg: 'transparent'
}

Input.isField = true

export default Input
