import React from 'react'
import Menu from 'components/icons/feather-menu.svg'
import styled from '@emotion/styled'
import Button from './Button'

const TransparentButton = styled(Button)`
  padding: 0;
  background-color: transparent;
  color: inherit;

  &:hover {
    background-color: 'transparent';
  }

  & > div {
    display: flex;
  }
`

const MenuButton = ({ color, size, title = '닫기', ...props }) => (
  <TransparentButton {...props}>
    <Menu color={color} size={size} title={title} />
  </TransparentButton>
)

export default MenuButton
