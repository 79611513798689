import React from 'react'
import { graphql } from 'gatsby'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import Layout from 'screens/shared/Layout'
import Search from 'screens/shared/Search'
import ProductList from 'screens/product/ProductList'
import Banner from 'screens/product/Banner'
import { Box, Flex, Card } from 'components/primitives'
import { Row, Column } from 'components/core/Layout'
import Hits from 'components/modules/Hits'
import Head from 'components/modules/Head'
import Heading from 'components/core/Heading'
import Text from 'components/core/Text'
import Tooltip from 'components/modules/Tooltip'
import Info from 'components/icons/feather-info.svg'
import { container } from 'components/core/Layout'
import Slider from 'components/slider'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// * Sorts product variant images by filename (e.g. main > thumbnail > rest) and
//   then attach series images at the end of the list. Lastly remove falsy
//   values in case series images don't exist. (i.e. no series images, etc.) [1]

const Sidebar = props => (
  <Box
    flex={['1 1 100%', null, null, '0 0 320px']}
    mr={[null, null, null, 56]}
    order={[null, null, null, -1]}
    as='aside'
    {...props}
  />
)

const Main = props => (
  <Box flex={['1 1 100%', null, null, '1']} as='main' {...props} />
)

const AttributeValue = props => <Text as='span' fontSize={3} {...props} />

const AttributeColumn = props => (
  <Column
    px={2}
    mb={2}
    width={[1, 1 / 2]}
    display='flex'
    alignItems='center'
    {...props}
  />
)

const DescriptionLabel = props => (
  <Text
    as='span'
    display='block'
    color='lightText'
    fontSize={1}
    mb={1}
    {...props}
  />
)

const AttributeKey = props => (
  <Text as='span' color='lightText' fontSize={3} mr={3} {...props} />
)

const AttributeList = ({ product, ...props }) => (
  <Card
    borderTop='1px solid'
    borderBottom='1px solid'
    color='text'
    mb={4}
    pt={2}
    {...props}
  >
    <Row mx={-2}>
      <AttributeColumn>
        <Flex alignItems='center'>
          <AttributeKey mr={1}>가격</AttributeKey>
          <Tooltip title='부가세가 포함되지 않은 비용입니다.'>
            <Info size={14} mr={3} />
          </Tooltip>
          <AttributeValue>
            ￦{product.price_per_coverage_without_tax.toLocaleString()} / m²
          </AttributeValue>
        </Flex>
      </AttributeColumn>
      <AttributeColumn />
      <AttributeColumn>
        <AttributeKey>박스 정보</AttributeKey>
        <AttributeValue>
          {product.coverage} m² ({product.pieces_per_box}장)
        </AttributeValue>
      </AttributeColumn>
      <AttributeColumn>
        <AttributeKey>사이즈</AttributeKey>
        <AttributeValue>{product.derived_size}</AttributeValue>
      </AttributeColumn>
      <AttributeColumn>
        <AttributeKey>타일 종류</AttributeKey>
        <AttributeValue>{product.tile_type}</AttributeValue>
      </AttributeColumn>
      <AttributeColumn>
        <AttributeKey>표면</AttributeKey>
        <AttributeValue>{product.surface}</AttributeValue>
      </AttributeColumn>
    </Row>
  </Card>
)

const Product = ({
  data: {
    product: { data },
    series: { edges }
  },
  ...props
}) => {
  const matches = useMediaQuery('(min-width:640px)')

  // [1]
  const unsortedVariants = edges.map(({ node }) => node.data)
  const otherVariants = unsortedVariants.filter(
    variant => variant.handle !== data.handle
  )
  const { images_series, description_series } = sortBy(unsortedVariants, [
    'name'
  ])[0]
  const productImages = sortBy(data.images, ['filename'])
  const allImages = compact([...productImages, ...images_series])
  return (
    <Layout
      {...container}
      py={3}
      display='flex'
      flex='1'
      flexWrap='wrap'
      as='div'
    >
      <Head title={data.name} />
      <Main mb={4}>
        <Slider mb={4} images={allImages} />
        <Box px={2}>
          <Banner product={data} />
          {data.obsolete && (
            <Card mx='auto' maxWidth={450} bg='muted' p={3} my={3}>
              해당 제품은 단종되었습니다.
            </Card>
          )}
          <Flex alignItems='center' mb={3}>
            <Heading fontWeight='heavy' fontSize={5} mr='auto'>
              {data.name}
            </Heading>
            {/* <Button
              onClick={() => setToggle(prevState => !prevState)}
              bg='text'
            >
              견적서 받기
            </Button> */}
          </Flex>
          {/* {toggle && <Banner />} */}
          <AttributeList product={data} />
          {data.description && (
            <Box mb={4}>
              <DescriptionLabel>제품 설명</DescriptionLabel>
              <AttributeValue as='p'>{data.description}</AttributeValue>
            </Box>
          )}
          {(otherVariants[0] || description_series) && (
            <DescriptionLabel>{data.series_ko} 시리즈</DescriptionLabel>
          )}
          <AttributeValue mb={2} as='p'>
            {description_series}
          </AttributeValue>
          {otherVariants[0] && <ProductList products={otherVariants} />}
        </Box>
      </Main>
      {matches && (
        <Sidebar>
          <Search hitsPerPage={6} location={props.location}>
            <Hits mb={2} />
          </Search>
        </Sidebar>
      )}
    </Layout>
  )
}

export default Product

export const productQuery = graphql`
  query Product($handle: String!, $series: String!) {
    product: airtable(
      table: { eq: "제품" }
      data: { handle: { eq: $handle } }
    ) {
      data {
        name
        images_series {
          id
          url
          filename
          size
          type
          thumbnails {
            small {
              url
              width
              height
            }
            large {
              url
              width
              height
            }
            full {
              url
              width
              height
            }
          }
        }
        images {
          id
          url
          filename
          size
          type
          thumbnails {
            small {
              url
              width
              height
            }
            large {
              url
              width
              height
            }
            full {
              url
              width
              height
            }
          }
        }
        type
        tile_type
        colors
        tags
        surface
        width
        length
        derived_size
        coverage
        derived_coverage
        price_per_coverage
        price_per_coverage_without_tax
        price_per_box
        pieces_per_box
        has_samples
        series
        series_ko
        series_variant
        handle
        handle_ko
        description_series
        description
        video_url
        shop_url
        created_at
        updated_at
        obsolete
      }
    }
    series: allAirtable(
      filter: { table: { eq: "제품" }, data: { series: { eq: $series } } }
    ) {
      edges {
        node {
          data {
            name
            images_series {
              id
              url
              filename
              size
              type
              thumbnails {
                small {
                  url
                  width
                  height
                }
                large {
                  url
                  width
                  height
                }
                full {
                  url
                  width
                  height
                }
              }
            }
            images {
              id
              url
              filename
              size
              type
              thumbnails {
                small {
                  url
                  width
                  height
                }
                large {
                  url
                  width
                  height
                }
                full {
                  url
                  width
                  height
                }
              }
            }
            type
            tile_type
            colors
            tags
            surface
            width
            length
            derived_size
            coverage
            derived_coverage
            price_per_coverage
            price_per_coverage_without_tax
            price_per_box
            pieces_per_box
            has_samples
            series
            series_ko
            series_variant
            handle
            handle_ko
            description_series
            description
            video_url
            shop_url
            created_at
            updated_at
            obsolete
          }
        }
      }
    }
  }
`
