import React from 'react'
import { Pagination as BasePagination } from 'react-instantsearch-dom'
import { Box } from 'components/primitives'

const Pagination = props => (
  <Box {...props}>
    <BasePagination
      showFirst={false}
      translations={{
        previous: '‹',
        next: '›',
        first: '«',
        last: '»',
        page(currentRefinement) {
          return currentRefinement
        },
        ariaPrevious: '이전 페이지',
        ariaNext: '다음 페이지',
        ariaFirst: '첫 페이지',
        ariaLast: '마지막 페이지',
        ariaPage(currentRefinement) {
          return `페이지 ${currentRefinement}`
        }
      }}
    />
  </Box>
)

export default Pagination
