import React from 'react'
import Text from './Text'

// Extends Text.

const Heading = props => (
  <Text as='h2' fontWeight='bold' fontSize={3} m={0} {...props} />
)

export default Heading
