import React from 'react'
import BaseTooltip from '@material-ui/core/Tooltip'
import BaseButton from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#181818',
    color: '#fff',
    fontSize: 12
  }
}))(BaseTooltip)

const TransparentButton = withStyles(theme => ({
  root: {
    padding: 0,
    minWidth: 0,
    backgroundColor: 'transparent',
    color: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))(BaseButton)

const StyledButton = withStyles(theme => ({
  root: {
    borderRadius: 2,
    fontSize: 12,
    backgroundColor: '#181818',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#3f3f3f'
    }
  }
}))(BaseButton)

const Tooltip = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(false)

  function handleTooltipClose() {
    setOpen(false)
  }

  function handleTooltipOpen() {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...props}
        >
          <TransparentButton onClick={handleTooltipOpen}>
            {children}
          </TransparentButton>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  )
}

export const ButtonTooltip = ({ buttonProps, children, ...props }) => {
  const [open, setOpen] = React.useState(false)

  function handleTooltipClose() {
    setOpen(false)
  }

  function handleTooltipOpen() {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...props}
        >
          <StyledButton onClick={handleTooltipOpen} {...buttonProps}>
            {children}
          </StyledButton>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  )
}

export default Tooltip
