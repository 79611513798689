import React from 'react'
import styled from '@emotion/styled'
import { Flex } from 'components/primitives'
import Input from 'components/core/Input'
import ChevronDown from 'components/icons/feather-chevron-down.svg'

const ClickableIcon = styled(ChevronDown)({ pointerEvents: 'none' })

const Select = styled(({ iconColor = 'muted', iconSize = 12, ...props }) => (
  <Flex width={1} alignItems='center'>
    <Input as='select' {...props} />
    <ClickableIcon ml={-24} color={iconColor} size={iconSize} />
  </Flex>
))``

Select.isField = true

export default Select
