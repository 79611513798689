import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Box } from 'components/primitives'

const getMaxWidth = px => parseInt(px) - 1

const breakpoints = props => ({
  xs: `@media screen and (max-width: ${getMaxWidth(
    props.theme.breakpoints[0]
  )}px)`,
  sm: `@media screen and (min-width: ${
    props.theme.breakpoints[0]
  }px) and (max-width: ${getMaxWidth(props.theme.breakpoints[1])}px)`,
  md: `@media screen and (min-width: ${
    props.theme.breakpoints[1]
  }px) and (max-width: ${getMaxWidth(props.theme.breakpoints[2])}px)`,
  lg: `@media screen and (min-width: ${
    props.theme.breakpoints[2]
  }px) and (max-width: ${getMaxWidth(props.theme.breakpoints[3])}px)`,
  xl: `@media screen and (min-width: ${props.theme.breakpoints[3]}px)`,
  print: `@media print`
})

const hidden = key => props =>
  props[key]
    ? {
        [breakpoints(props)[key]]: {
          display: 'none'
        }
      }
    : null

const Hide = styled(Box)`
  ${hidden('xs')}
  ${hidden('sm')}
  ${hidden('md')}
  ${hidden('lg')}
  ${hidden('xl')}
  ${hidden('print')}
`

Hide.propTypes = {
  ...Box.propTypes,
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  print: PropTypes.bool
}

export default Hide
