import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'components/primitives'
import { border } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

// Extends Box.

const Base = styled(Box, {
  shouldForwardProp
})(border)

const Divider = props => (
  <Base
    as='hr'
    border={0}
    borderBottom='1px solid'
    borderColor='muted'
    ml={0}
    mr={0}
    {...props}
  />
)

export default Divider
