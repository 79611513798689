import styled from '@emotion/styled'
import { Box } from 'components/primitives'
import PropTypes from 'prop-types'

// Extends Box.
// * truncated: adds ellipsis
// * caps: capitalizes letters

const truncated = ({ truncated }) =>
  truncated && {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }

const caps = ({ caps }) =>
  caps && {
    textTransform: 'uppercase',
    letterSpacing: 'caps'
  }

const Text = styled(Box)(truncated, caps)

Text.propTypes = {
  truncated: PropTypes.bool,
  caps: PropTypes.bool
}

Text.defaultProps = {
  as: 'p'
}

Text.displayName = 'Text'

export default Text
