import React from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
import 'components/slider.css'
import { Box, Card } from 'components/primitives'

export default ({ images, ...props }) => {
  const params = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 2,
    spaceBetween: 8,
    noSwiping: true,
    breakpoints: {
      640: {
        slidesPerView: 1
      }
    }
  }
  return (
    <Box {...props}>
      <Swiper {...params}>
        {images.map(i => (
          <Card
            key={i.id}
            css={{
              backgroundSize: 'cover',
              backgroundImage: `url(${i.url})`
            }}
            bg='muted'
            py={225}
            borderRadius={1}
            borderWidth={1}
            borderStyle='solid'
            borderColor='lightGray'
          />
        ))}
      </Swiper>
    </Box>
  )
}
