import React from 'react'
import styled from '@emotion/styled'
import {
  compose,
  space,
  color,
  layout,
  flexbox,
  typography,
  border,
  shadow,
  background
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

// * Uses styled HOC so extended elements can use `as` prop.
// * Adds minWidth: 0 to handle if it's used as a flex item.
//   https://styled-system.com/guides/build-a-box#base-styles

export const Box = styled('div', {
  shouldForwardProp
})(
  {
    boxSizing: 'border-box'
  },
  compose(
    space,
    color,
    layout,
    flexbox,
    typography
  )
)

Box.defaultProps = {
  minWidth: 0
}

export const Card = styled(Box, {
  shouldForwardProp
})(
  compose(
    border,
    shadow,
    background
  )
)

export const Flex = props => <Box display='flex' {...props} />
