import React from 'react'
import SearchBox from 'components/modules/SearchBox'
import MenuSelect from 'components/modules/MenuSelect'
import { Card } from 'components/primitives'
import { Row, Column } from 'components/core/Layout'

const SearchControl = props => {
  // const [suggestions, setSuggestions] = React.useState([])
  // React.useEffect(() => {
  //   let n = 0
  //   let randoms = []
  //   while (n < 5) {
  //     randoms.push(random(0, TAGS.length - 1))
  //     n++
  //   }
  //   const uniques = uniq(randoms)
  //   const keys = uniques.map(item => TAGS[item])
  //   setSuggestions(keys)
  // }, [])

  return (
    <Card bg='primary' p={2} borderRadius={16} {...props}>
      <SearchBox delay={700} mb={3} />
      {/* <Box ml={1} mb={3}>
        <Text as='p' mb={1} fontSize={0} color='background' mr={2}>
          검색어 제안
        </Text>
        {suggestions.map(item => (
          <Suggestion key={item}>{item}</Suggestion>
        ))}
      </Box> */}
      <Row mx={-2}>
        <Column width={1 / 2} px={2} mb={3}>
          <MenuSelect
            attribute='tile_type'
            id='tile_type'
            name='tile_type'
            label='타일 종류'
            limit={30}
          />
        </Column>
        <Column width={1 / 2} px={2} mb={3}>
          <MenuSelect
            attribute='colors'
            id='colors'
            name='colors'
            label='색상'
            limit={30}
          />
        </Column>
        <Column width={1 / 2} px={2}>
          <MenuSelect
            attribute='filterable_size'
            id='size'
            name='size'
            label='사이즈'
            limit={30}
          />
        </Column>
        <Column width={1 / 2} px={2}>
          <MenuSelect
            attribute='surface'
            id='surface'
            name='surface'
            label='표면'
            limit={30}
          />
        </Column>
      </Row>
    </Card>
  )
}

export default SearchControl
